import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createSearchParams, generatePath, Link, useNavigate } from 'react-router-dom'
import { isEmpty, size } from 'lodash'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  Stack,
  Text,
  Tooltip,
  HStack,
  Button,
  VStack,
  Image,
  UnorderedList,
  ListItem,
  Divider,
  Spacer,
  Wrap,
  Center,
} from '@chakra-ui/react'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import ReactGA from 'react-ga4'

import styles from './basket.module.css'
import { Label } from 'components/layout/label'
import { useBasketStore } from 'shared/stores/basketStore'
import { USDollar } from 'shared/utils/currenciesFormatters'
import TrashIcon from 'shared/icons/misc/trash.svg'
import QualityControlLogo from 'shared/images/quality-control-crest.png'
import Logo from 'shared/icons/logo.svg'

import { APP_PATHS } from 'paths'
import { useUserContextState } from 'shared/contexts/user-context-provider'

import { Checkbox } from 'components/elements/checkbox'
import { useIndexMySubscriptions } from 'shared/queries/subscription'
import { FormikInput } from '../../../components/elements/input'
import { useValidateCouponCode } from '../../../shared/queries/coupon'
import moment from 'moment/moment'
import { useIndexServiceConfig } from 'shared/queries/serviceConfig'
import { useReadMyUserProfile } from 'shared/queries/userProfile'

const PERCENTS = 100

export const BasketPage = () => {
  const { items, addCouponToItem } = useBasketStore()
  const { data: subscriptions = [], isLoading: isSubscriptionsLoading } =
    useIndexMySubscriptions({ statuses: ['ACTIVE', 'PENDING'] })
  const { data: serviceConfig = [{ availabelStates: [] }], isLoading: isServiceConfigLoading } =
    useIndexServiceConfig()
  const { data: profile, isLoading: isProfileLoading } = useReadMyUserProfile()
  const navigate = useNavigate()
  const [couponCode, setCouponCode] = useState()
  const [couponData, setCouponData] = useState()
  const [couponError, setCouponError] = useState()
  const productIds = items.map((item) => item.id)

  const {
    data,
    error,
    isLoading: isCouponLoading,
  } = useValidateCouponCode({ code: couponCode, productIds }, !!couponCode)
  useEffect(() => {
    if (data) {
      const { availableTill, maxRedemptions, timesRedeemed } = data
      if (availableTill) {
        const currentDate = moment()
        const couponAvailableTill = moment(availableTill)
        if (couponAvailableTill.isBefore(currentDate)) {
          setCouponError('Coupon is expired')
          return
        }
      }
      if (maxRedemptions) {
        if (timesRedeemed >= maxRedemptions) {
          setCouponError('Coupon code is not active')
          return
        }
      }
      setCouponData(data)
      addCouponToItem(productIds[0], data)
    }
    if (error) {
      if (error?.error?.code === 'MNC-33') setCouponError(error?.message || 'Coupon is invalid')
    }
  }, [data, error])

  useEffect(() => {
    if (!isEmpty(items)) {
      const productItems = items.map((item, index) => ({
        item_id: item.id,
        item_name: item.title,
        affiliation: item.medName,
        index: index,
        price: item.priceInCents / 100 || item.totalPriceInCents / 100,
        quantity: item.amount,
        ndc: item.ndc,
        sku: item.sku,
      }))

      const totalPrice = () =>
        items.reduce(
          (sum, item) =>
            sum + item.amount * ((item.priceInCents || item.totalPriceInCents) / 100),
          0
        )

      const itemList = {
        currency: 'USD',
        value: totalPrice(),
        items: productItems,
      }
      //https://developers.google.com/tag-platform/gtagjs/reference/events#view_cart
      ReactGA.event('view_cart', itemList)
    }
  }, [items])
  const { user } = useUserContextState()
  const totalAmount = useMemo(() => {
    return items.reduce((a, b) => {
      a = a + b.totalPrice
      return a
    }, 0)
  }, [items])

  let discountAmountLabel = ''
  let discountPercentage = 0
  let discountAmountUSD = 0

  if (
    !isEmpty(couponData) ||
    ((items[0]?.customCoupon || items[0]?.defaultCoupon) && isEmpty(user))
  ) {
    const { discount, discountAmount: discountAmountInCents } =
      couponData || items[0].customCoupon || items[0].defaultCoupon
    const discountAmount = discount ? (totalAmount * discount) / PERCENTS : discountAmountInCents
    const totalAmountWithDiscount = totalAmount - discountAmount
    discountAmountLabel = USDollar.format(totalAmountWithDiscount / 100)
    discountPercentage = parseFloat(((discount / PERCENTS) * 100).toFixed(1))
    discountAmountUSD = USDollar.format(discountAmountInCents / 100)
  }

  const productsCount = useMemo(() => {
    return items.reduce((a, b) => {
      a = a + b.amount
      return a
    }, 0)
  }, [items])
  if (isSubscriptionsLoading || isProfileLoading || isServiceConfigLoading) {
    return null
  }
  const handleValidateCoupon = ({ coupon }) => {
    setCouponCode(coupon)
  }
  const isBlockedState =
    !!profile &&
    profile.address?.addressRegion &&
    !serviceConfig[0].availabelStates?.includes(profile.address.addressRegion)
  const moreThanOneSelected = productsCount > 1
  const hasActiveSubscriptions = size(subscriptions) > 0
  const onConfirm = () => {
    if (isEmpty(items)) {
      console.log('Nothing to checkout')
    }
    const product = items[0]

    if (isEmpty(user)) {
      navigate({
        pathname: APP_PATHS.registrationStep1,
        search: `?${createSearchParams({ toBuy: '1' })}`,
      })
    } else {
      const paymentPath = generatePath(APP_PATHS.orderSubscription, {
        id: product.id,
        isPhoneNumberRequired: false,
      })
      if (isEmpty(couponData)) {
        navigate(paymentPath)
      } else {
        navigate({
          pathname: paymentPath,
          search: `?${createSearchParams({ couponCode: couponData.referenceId })}`,
        })
      }
    }
  }
  const isBasketEmpty = items.length === 0

  const defaultCouponCode =
    items?.[0]?.customCoupon?.referenceId || items?.[0]?.defaultCoupon?.referenceId || ''

  return (
    <div className={styles.wrapper}>
      <Label>Basket</Label>
      {!isBasketEmpty && (
        <Stack direction={{ base: 'column', md: 'row' }} w="100%">
          <VStack alignItems="flex-start" spacing="0">
            <Text color="#D537A8" fontSize="20px" fontWeight="700">
              Next Steps
            </Text>
            <Text
              fontSize="20px"
              fontWeight="400"
              mb="50px"
              textAlign="left"
              maxW="880px"
              as="i"
            >
              Once you have clicked{' '}
              <Text color="#D537A8" as="span">
                “Next”
              </Text>
              you will be invited to take a short 5min questionnaire about your health to
              determine your eligibility, you’ll then be asked to enter payment details.
            </Text>
          </VStack>
          <Spacer />
          {items.length ? (
            <Box className={styles.buttons} width={{ base: '100%', md: 'auto' }}>
              <button
                disabled={hasActiveSubscriptions || moreThanOneSelected || isBlockedState}
                className={styles.continue}
                onClick={onConfirm}
              >
                Next
              </button>
            </Box>
          ) : null}
        </Stack>
      )}
      {!isBasketEmpty && (
        <BasketItem
          item={items[0]}
          user={user}
          defaultCouponCode={defaultCouponCode}
          handleValidateCoupon={handleValidateCoupon}
          isCouponLoading={isCouponLoading}
          couponData={couponData}
          couponError={couponError}
          productIds={productIds}
          totalAmount={totalAmount}
          discountAmountLabel={discountAmountLabel}
          discountPercentage={discountPercentage}
          discountAmountUSD={discountAmountUSD}
          hasActiveSubscriptions={hasActiveSubscriptions}
          moreThanOneSelected={moreThanOneSelected}
          isBlockedState={isBlockedState}
          onConfirm={onConfirm}
        />
      )}
      {isBasketEmpty && (
        <Text fontSize="20px" fontWeight="600" mb="100px">
          Please visit our{' '}
          <Link className={styles.href_main} to={APP_PATHS.treatmentPlans} color="#D537A8">
            Treatment Plan Page
          </Link>{' '}
          and then head back here to start your weight loss journey.
        </Text>
      )}
      {hasActiveSubscriptions && (
        <Stack alignItems="end" w="100%">
          <Text color="red">
            You can&apos;t start new subscription because you already have active subscription.
            Please review active subscription{' '}
            <Link className={styles.href} to={APP_PATHS.myProfileBillingPage}>
              here
            </Link>{' '}
            or you can cancel it{' '}
            <Link className={styles.href} to={APP_PATHS.myProfileReturnsPage}>
              here
            </Link>
            .
          </Text>
        </Stack>
      )}
      {isBlockedState && (
        <Stack alignItems="end" w="100%">
          <Text color="red">
            Service temporary unavailable for your state, we will notify you when its will be
            updated.
          </Text>
        </Stack>
      )}
      {moreThanOneSelected && (
        <Stack alignItems="end" w="100%">
          <Text color="red">You can&apos;t create subscription for more that one product.</Text>
          <Text color="red">Please remove extra products from your basket.</Text>
        </Stack>
      )}
      {!isBasketEmpty && <QualityControl />}
    </div>
  )
}

const QuestionTooltip = () => {
  return (
    <Tooltip placement={'top'} label={'Physician engagement, making sure on right dose etc…'}>
      <QuestionOutlineIcon />
    </Tooltip>
  )
}

const BasketItem = ({
  item,
  defaultCouponCode,
  handleValidateCoupon,
  isCouponLoading,
  couponData,
  couponError,
  productIds,
  totalAmount,
  discountAmountLabel,
  discountPercentage,
  discountAmountUSD,
  hasActiveSubscriptions,
  moreThanOneSelected,
  isBlockedState,
  onConfirm,
}) => {
  const { reduceItemAmount, items, removeItem } = useBasketStore()

  const reduceAmount = useCallback(
    (amount) => {
      if (items.find((i) => i.id === item.id).amount === 1 && amount === -1) {
        removeItem(item.id)

        const removeEventData = {
          items: [
            {
              item_id: item.id,
              item_name: item.title,
              affiliation: item.medName,
              ndc: item.ndc,
              sku: item.sku,
            },
          ],
        }
        //https://developers.google.com/tag-platform/gtagjs/reference/events#remove_from_cart
        ReactGA.event('remove_from_cart', removeEventData)
      } else {
        reduceItemAmount(item.id, amount)
      }
    },
    [reduceItemAmount, items, removeItem]
  )

  const [checkBoxState, setCheckBoxState] = useState(true)

  return (
    <Box className={styles.item_wrapper}>
      <Text className={styles.label_product}>{item.title}</Text>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-around"
        alignItems={{ base: 'center', md: 'flex-start' }}
        w="100%"
        gap={12}
      >
        <VStack alignItems="start" h="100%" w="100%" gap={5}>
          <Box
            bgColor={'var(--third-bg)'}
            h="240px"
            w={'100%'}
            borderRadius="10px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              maxH="220px"
              fit="contain"
              src={item?.secondaryImageUrl || item.imageUrl}
              alt={item.title}
            />
          </Box>
          {item.productDetails && (
            <VStack alignItems="flex-start">
              <Text fontSize="22px" fontWeight="700" className={styles.label_secondary}>
                Product Details
              </Text>
              <Text
                fontSize="18px"
                lineHeight="23px"
                fontWeight="400"
                textAlign="left"
                maxW="950px"
              >
                {item.productDetails}
              </Text>
            </VStack>
          )}
          {item.subscriptionDetails && (
            <VStack alignItems="flex-start">
              <Text fontSize="22px" fontWeight="700" className={styles.label_secondary}>
                Subscription
              </Text>
              <Text
                fontSize="18px"
                lineHeight="23px"
                fontWeight="400"
                textAlign="left"
                maxW="950px"
                dangerouslySetInnerHTML={{ __html: item.subscriptionDetails }}
              />
            </VStack>
          )}
          {item.deliveryInfo && (
            <VStack alignItems="flex-start">
              <Text fontSize="22px" fontWeight="700" className={styles.label_secondary}>
                Delivery
              </Text>
              <Text
                fontSize="18px"
                lineHeight="23px"
                fontWeight="400"
                textAlign="left"
                maxW="950px"
              >
                {item.deliveryInfo}
              </Text>
            </VStack>
          )}
        </VStack>
        <VStack alignItems="start" h="100%" w="100%" spacing={10}>
          <HStack w="100%" alignItems={'flex-start'}>
            <VStack alignItems="start" spacing={8}>
              <Text fontSize="26px" fontWeight="400 ">
                Quantity
              </Text>
              <HStack spacing={5}>
                <Box
                  borderRadius="7px"
                  bgColor={'var(--third-bg)'}
                  boxSize={'27px'}
                  placeContent={'center'}
                  display={'grid'}
                  cursor={'pointer'}
                  onClick={() => reduceAmount(-1)}
                >
                  {item.amount === 1 ? <img src={TrashIcon} alt={'Trash Icon'} /> : '-'}
                </Box>
                <Text as="span" fontWeight={'700'}>
                  {item.amount}
                </Text>
                <div style={{ opacity: 0 }}>+</div>
              </HStack>
            </VStack>
            <Spacer />
            <VStack maxWidth="30vw" spacing={8} alignItems={'flex-start'} pr="20px">
              <Text fontSize="26px" fontWeight="400 ">
                Price
              </Text>
              <Text as="span" fontSize={'25px'} fontWeight={'700'} lineHeight={'32px'}>
                {USDollar.format(item.priceInCents / 100)}
              </Text>
            </VStack>
          </HStack>
          {item.isPrescriptionRequired ? (
            <p className={styles.label}>
              <Checkbox
                checked={checkBoxState}
                onChange={setCheckBoxState}
                label={
                  <p>
                    Send me a reminder when new prescription is needed &nbsp;
                    <QuestionTooltip />
                  </p>
                }
                className={styles.checkbox_label}
              />
            </p>
          ) : (
            <p style={{ height: '50px' }} />
          )}
          <Divider borderColor={'var(--footer)'} borderWidth="1px" />
          <Wrap w="100%">
            <Center>
              <Text fontSize={'20px'} lineHeight={'26px'}>
                Discount Code:
              </Text>
            </Center>
            <Spacer />

            <Formik
              enableReinitialize
              noValidate
              initialValues={{ coupon: defaultCouponCode }}
              onSubmit={handleValidateCoupon}
              validationSchema={Yup.object({
                coupon: Yup.string(),
              })}
            >
              {(formik) => (
                <Form noValidate>
                  <Tooltip
                    placement="bottom"
                    hasArrow
                    px={2}
                    py={1}
                    label="You can use your code."
                    bg={'var(--secondary-gray)'}
                    rounded={8}
                    fontSize={'lg'}
                  >
                    <HStack alignItems="flex-start">
                      <FormikInput
                        type="text"
                        placeholder=""
                        name="coupon"
                        color={'white'}
                        formik={formik}
                        inputProps={{
                          disabled: isCouponLoading,
                          style: {
                            border: '1px solid #241426',
                            padding: '4px 20px',
                            fontSize: '20px',
                            fontWeight: '450',
                            width: ' 190px',
                          },
                        }}
                        errors={{
                          coupon: couponError,
                        }}
                      />
                      <Button
                        type="submit"
                        colorScheme="pink"
                        bg="var(--accent)"
                        fontWeight="600"
                        borderRadius="10px"
                        disabled={isCouponLoading || productIds.length === 0}
                      >
                        {couponData ? 'Applied' : 'Apply'}
                      </Button>
                    </HStack>
                  </Tooltip>
                </Form>
              )}
            </Formik>
          </Wrap>
          <Divider borderColor={'var(--footer)'} borderWidth="1px" />
          <HStack w="100%">
            <Text fontSize={'25px'} lineHeight={'32px'} fontWeight={'600'}>
              Total{' '}
              {discountAmountLabel && (
                <Tooltip
                  placement="bottom"
                  hasArrow
                  px={2}
                  py={1}
                  label={
                    <>
                      The discount is:{' '}
                      <span style={{ color: '#F13A3B', fontWeight: 600 }}>
                        {discountPercentage ? `${discountPercentage}%` : `${discountAmountUSD}`}
                      </span>
                    </>
                  }
                  bg={'var(--secondary-gray)'}
                  rounded={8}
                  fontSize={'lg'}
                >
                  <Text as="span" fontWeight={'400'} fontStyle={'italic'}>
                    (Discounted)
                  </Text>
                </Tooltip>
              )}
            </Text>
            <Spacer />
            <Text fontSize={'25px'} fontWeight={'600'} lineHeight={'32px'}>
              <Text as="span">({items.length} item): </Text>
              {discountAmountLabel ? discountAmountLabel : USDollar.format(totalAmount / 100)}
            </Text>
          </HStack>
          {items.length ? (
            <div className={styles.buttons} style={{ width: 'none' }}>
              <button
                disabled={hasActiveSubscriptions || moreThanOneSelected || isBlockedState}
                className={styles.continue}
                onClick={onConfirm}
              >
                Next
              </button>
            </div>
          ) : null}
        </VStack>
      </Stack>
    </Box>
  )
}

const QualityControl = () => {
  return (
    <Box
      borderRadius="2xl"
      p={7}
      bgColor="#D5CEDE"
      maxW="960px"
      mt="40px"
      ml={{ base: '0', md: '50px' }}
    >
      <Stack spacing={10} direction={['column', 'row']} alignItems="center">
        <VStack align="center" minW="130px" spacing={1}>
          <Box borderRadius="full" bgColor="white">
            <Image src={QualityControlLogo} alt="Quality Control Crest" boxSize="110px" />
          </Box>
          <Image src={Logo} alt="Quality Control Crest" w="40px" />
          <Text fontWeight="600" fontSize="9px" lineHeight="11.54px" textAlign="center">
            QUALITY CONTROL PROMISE
          </Text>
        </VStack>
        <UnorderedList
          textAlign="start"
          fontWeight="600"
          fontSize="16px"
          lineHeight="20.51px"
          spacing={3}
          fontStyle="italic"
        >
          <ListItem>
            Our compound products are prepared in a sterile facility aligning to USP797 standards
            with an active ingredients that is fully trackable.
          </ListItem>
          <ListItem>
            Our compound products are sent for testing to an independent lab before being
            delivered to you.
          </ListItem>
          <ListItem>
            If you have any other questions or if we can help you in any way please don&apos;t
            hesitate to contact us at{' '}
            <Link to="mailto:help@maion.ai" className={styles.href_main}>
              help@maion.ai
            </Link>
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  )
}
